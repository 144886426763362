@mixin font-haas-light{
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 400;
    font-style: normal;    
}
@mixin font-haas-regular{
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-style: normal;   
}
@mixin font-haas-medium{
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-style: normal;   
}
@mixin font-baskerville-regular-italic{
    font-family: baskerville-urw, serif;
    font-weight: 400;
    font-style: italic;    
}

body {
    background-color: #242424;
    color: white;
    @include font-baskerville-regular-italic;
}
// @import './resources//css/font-face.scss';
@import './resources//css/utility.scss';

.text-white{
    color: #FFF !important;
}
.mt-0{
    margin-top: 0 !important;
}
.app {
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    max-height: var(--vh100);
    height: var(--vh100);
    max-width: var(--vw100);
    width: var(--vw100);
    &.justify-center{
        justify-content: center;
    }
    &__header {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        // flex-direction: row;
        .leave-button {
            @include font-haas-medium;
            padding: 10px 0;
            user-select: none;
            // border: 1px solid #FFFFFF;
            // border-radius: 10px;
            font-size: 12px;
            // line-height: 14px;
            text-align: center;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            font-style: normal;
        }
    }

    &__main-content{
        display: flex;
        flex-grow: 1;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        max-height: 500px;
    }

    &__logo {
        max-width: 160px;
        margin: 0 auto;
        @include mq('580px', 'height') {
            max-width: 200px;
        }
    }

    &__recipient {
        @include font-haas-medium;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.08em;
        margin: 0 0 20px;
        text-transform: uppercase;
    }

    &__logo-line-3 {
        margin: 7px 0 0;
        @include font-haas-medium;
        font-style: normal;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.08em;
        text-transform: uppercase;
    }

    &__logo-line-2 {
        // margin-top: 30px;
        margin: 10px 0 0;
        @include font-haas-medium;
        font-style: normal;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0.08em;
        text-transform: uppercase;
    }

    &__instructions {
        @include font-baskerville-regular-italic;
        font-style: italic;
        line-height: 1.2;
        color: #666666;
        max-width: 230px;
        margin: 5vh auto;
        font-size: 18px;
        @include mq('580px', 'height') {
            font-size: 28px;
        }
    }

    &__start-game {
        border: 1px solid #FDD18A;
        border-radius: 10px;
        padding: 14px 15px;
        @include font-haas-medium;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;

        /* Yellow */

        color: #FDD18A;
        max-width: 180px;
        margin: 20px auto 40px;
        user-select: none;
    }

    &__lobby-count {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        // margin-top: 30px;

        .app__lobby-count-item {
            margin-left: 15px;
            margin-right: 15px;
            img {
                max-width: 64px;
            }

            P {
                @include font-haas-medium;
                font-style: normal;
                font-size: 13px;
                line-height: 22px;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            .grey {
                color: #666666;
            }

            .green {
                color: #016E75;
            }

            .gold {
                color: #FDD18A;
            }

            .red {
                color: #C3494F;
            }
        }
    }

    &__lobby-devices {
        display: flex;
        flex-direction: row;
        margin: 20px auto;

        img {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    svg {
        margin: 0 auto;
        border: 10px solid #1E1E1E;
    }
    &__completed{
        display: flex;
        flex-direction: column;
        .completed-link{
            @include font-haas-medium;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            user-select: none;
        }   
        .app__start-game.link{
            display: inline-flex;
            align-items: center;
            margin-bottom: 5px;
            user-select: none;
            img{
                width: 17px;
                height: 17px;
                margin-left: 5px;
            }
        }
        .app__start-game.play-again{
            color: #8C8C8C;
            border-color: #8C8C8C;
            user-select: none;
        }
        .completed-link.kerve{
            color: #8C8C8C;
            user-select: none;
        }
    }
}

#wrapper {
	position: absolute;
	width: 100%;
	top: 0;
}

canvas {
	display: inline-block;
}

.overlay-wrapper{
    overflow: hidden;
    left: 0px;
    bottom: 0px;
    position: absolute;
    width: 100%;
}
#overlay {

	width: 100%;
	padding: 30px 0;
	background-color: #fff;
    color: #000;
	border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;    
}

.modal {
	position: absolute;
    height: 100%;
	left: 0;
	top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 0 13%;
    &__outer {
        background: #fff;
        color: #000;
        padding: 20px;
        border-radius: 15px;
        max-width: 360px;
        width: 100%;
    }
    &__inner {
        width: 100%;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }
    &__heading{
        font-size: 32px;
        line-height: 1.2;
    }
    &__body{
        @include font-haas-regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        @include mq('580px', 'height') {
            font-size: 20px;
        }
    }
    &__button{
        background: #000;
        padding: 14px;
        line-height: 1;
        color: #FFF;
        margin: 15px 0;
        @include font-haas-medium;
        text-transform: uppercase;
        border-radius: 10px;
        display: inline-block;
        user-select: none;
    }
    &__id{
        @include font-haas-medium;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.spin-text-1, .spin-text-2{
    @include font-haas-light;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.08em;
    font-style: normal;    
    margin: 5px 0;
}
.spin-text-1{
    @include font-haas-medium;
    text-transform: uppercase;
    font-size: 20px;
}

.screen_light {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: 100%, auto;
    background-repeat: no-repeat;

    &__disable-pointer {
        pointer-events: none;
    }
}