@use 'sass:math';

/*Returns a CSS clamp function which resolves to $size-at-min-width when page width is
below $min-width,
$size-at-max-width when page width is above $max-width,
and linearly scales between the two between $min-width and $max-width*/

@function clamp-calc($min-width, $max-width, $size-at-min-width, $size-at-max-width) {
  $slope: math.div(($size-at-max-width - $size-at-min-width), ($max-width - $min-width));
  $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
  $return-value: clamp(#{$size-at-min-width}, #{-1 * $min-width * $slope + $size-at-min-width} + #{math.div(($size-at-max-width - $size-at-min-width), ($max-width - $min-width))} * 100vw, #{$size-at-max-width});
  @return $return-value;
}


/*
Mobile first media query mixin
source - https://jonsuh.com/blog/managing-responsive-breakpoints-with-sass-maps/
*/
@mixin mq($mq-breakpoint, $xy: width) {
    /*
    If $mq-breakpoint is a key that exists in
    $mq-breakpoints, get and use the value
    */
    @media screen and (min-#{$xy}: #{$mq-breakpoint}) {
      @content;
    }
}
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
@function pxToRem($size) {
  @return (strip-unit($size) / 16) * 1rem;
}

@mixin bodyHeaderPaddingTop{
  padding-top: $header-height;
  @include mq(lg) {
      padding-top: $header-height-lg;
  }
}